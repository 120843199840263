<template>
  <div class="dashboard pb-12">
    <v-app-bar flat height="100" color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <div class="container">
        <v-toolbar-title class="primary--text title font-weight-bold">
          Dashboard
        </v-toolbar-title>
      </div>
    </v-app-bar>

    <div class="container">
      <v-row dense>
        <v-col cols="12" :sm="6">
          <v-row dense>
            <v-col>
              <v-card height="200">
                <div class="pa-4">
                  <h3 class="title">Users</h3>
                  <h3 class="display-1 font-weight-bold">100000</h3>
                </div>
              </v-card>
            </v-col>

            <v-col>
              <v-card height="200">
                <div class="pa-4">
                  <h3 class="title">Users</h3>
                  <h3 class="display-1 font-weight-bold">100000</h3>
                </div>
              </v-card>
            </v-col>

            <v-col>
              <v-card height="200">
                <div class="pa-4">
                  <h3 class="title">Users</h3>
                  <h3 class="display-1 font-weight-bold">100000</h3>
                </div>
              </v-card>
            </v-col>

            <v-col>
              <v-card height="200">
                <div class="pa-4">
                  <h3 class="title">Users</h3>
                  <h3 class="display-1 font-weight-bold">100000</h3>
                </div>
              </v-card>
            </v-col>

            <v-col>
              <v-card height="200">
                <div class="pa-4">
                  <h3 class="title">Users</h3>
                  <h3 class="display-1 font-weight-bold">100000</h3>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" :sm="6">
          <v-row dense>
            <v-col>
              <v-card height="408">
                <div class="pa-4">
                  <h3 class="title">Trends</h3>
                  <h3 class="display-1 font-weight-bold">Graph Here</h3>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'

export default {
  name: 'DashboardPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
  },
}
</script>
